define("component/page-head", [
    "vue",
    "text!component/page-head.html",
    "css!component/page-head.css",
    "service/bus",
    "service/user"
], function (Vue, tmpl, css, EventBus, UserService) {
    'use strict';

    return Vue.extend({
        template: tmpl,

        data: function () {
            return {
                search: "",
                displaySearch: false,
                hasToken: UserService.getTokenSync()
            }
        },

        ready: function () {
            var vm = this;
            EventBus.$on("user.login", function () {
                vm.hasToken = true;
            });
            EventBus.$on("user.logout", function () {
                vm.hasToken = false;
            });
        },

        watch: {
            searchText: function (val, oldVal) {
                this.search = val;
                if (this.search && this.search.length > 0) {
                    this.displaySearch = true;
                } else {
                    this.displaySearch = false;
                }
            }
        },

        computed: {
            searchText: function () {
                return this.$route.query.s || "";
            }
        },

        methods: {
            showLogin: function () {
                UserService.getToken().catch(function () {
                    EventBus.$emit("login");
                })
            },
            Logout: function () {
                UserService.logout();
            },
            showSearch: function () {
                this.displaySearch = !this.displaySearch;
                if (!this.displaySearch) {
                    this.search = "";
                    this.$router.go({
                        query: {
                            s: ""
                        }
                    });
                }
            },
            SubmitSearch: function () {
                var vm = this;
                vm.$router.go({
                    query: {
                        s: vm.search
                    }
                });
            }
        }
    });
});
