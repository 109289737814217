define("component/page-footer", [
    "vue", 
    "text!component/page-footer.html", 
    "css!component/page-footer.css"
], function(Vue, tmpl, css){
    'use strict';
    
    return Vue.extend({
        template : tmpl
    });
});
