define("component/post-list", [
    "vue",
    "text!component/post-list.html",
    "css!component/post-list.css",
    "lodash",
    "vue-lazyload",
    "vue-touch-ripple",
    "service/bus",
    "service/user"
], function(Vue, tmpl, css, _, lazyload, VueTouchRipple, EventBus, UserService) {
    'use strict';

    Vue.use(lazyload, {
        preLoad: 2,
        loading: BASE_PATH + '/images/common/ring.gif',
    })

    Vue.use(VueTouchRipple)

    return Vue.extend({
        props: {
            src: {
                type: Array,
                required: true,
                default: []
            },
            canloadmore: {
                type: Boolean,
                required: false,
                default: false,
                twoWay: true
            }
        },
        data: function() {
            return {
                editable: UserService.getTokenSync(),
                needLoadMore: false,
                Items: [],
                column_count: 4,
                loading: false,
                columns: []
            }
        },
        computed: {
            showLoadmore: function() {
                return this.canloadmore && !this.loading;
            },
            showLoading: function() {
                return this.canloadmore && this.loading;
            },

            src: {
                set: function(value) {
                    var vm = this;

                    this.Items = value;

                    this.columns = _.range(this.column_count).map(function() {
                        return [];
                    });

                    var i = 0;
                    value.forEach(function(val) {
                        vm.columns[(i % vm.column_count)].push(val);
                        i++;
                    });
                    this.loading = false;
                },
                get: function() {
                    return this.Items;
                }
            }
        },
        ready: function() {
            var vm = this;
            /**
             * 监听window resize 事件
             */
            window.addEventListener("resize", function() {
                var old = vm.column_count;

                if (vm.viewport().width <= 767) {
                    vm.column_count = 1;
                } else {
                    vm.column_count = 4;
                }

                if (old != vm.column_count) {
                    var i = 0,
                        temp = _.range(vm.column_count).map(function() {
                            return [];
                        });
                    vm.Items.forEach(function(val) {
                        temp[(i % vm.column_count)].push(val);
                        i++;
                    });
                    vm.columns = temp;
                }
            });

            var ev = document.createEvent('Event');
            ev.initEvent('resize', true, true);
            window.dispatchEvent(ev);

            EventBus.$on("user.login", function() {
                vm.editable = true;
            });
            EventBus.$on("user.logout", function() {
                vm.editable = false;
            });
        },
        methods: {
            loadMore: function() {
                EventBus.$emit("load.more");
                this.loading = true;
            },
            viewport: function() {
                var e = window;
                var a = 'inner';
                if (!('innerWidth' in window)) {
                    a = 'client';
                    e = document.documentElement || document.body;
                }
                return {
                    width: e[a + 'Width'],
                    height: e[a + 'Height']
                }
            },
            handleClick: function(e) {
                if (e.target && e.target.className == 'hashtag') {
                    this.$router.go({
                        path: '/tag/' + _.trim(e.target.text, '#')
                    })
                }
            },
            handleDetail: function(product_id) {
                if (IN_PRO) {
                    var root = IN_PRO.root || '';
                    return root + '/post/' + product_id;
                }

                var reg = /#([^#]+)/i;
                return location.href.replace(reg, "") + '#!/post/' + product_id;
            },
            editProduct: function(product_id) {
                EventBus.$emit("product.edit", product_id);
            }
        },
        template: tmpl
    });
});
