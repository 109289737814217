define("service/user", [
    "vue",
    "vue-resource",
    "promise",
    "service/bus"
], function (Vue, http, _promise, EventBus) {
    'use strict';

    Vue.use(http);

    var prefix_path = MAIN_CONFIH.API_ROOT;

    Vue.http.options.timeout = 0;

    var self = {
        getTokenSync: function () {
            return localStorage.getItem('token');
        },
        getToken: function () {
            return new Promise(function (resolve, reject) {
                var token = localStorage.getItem('token');
                if (token) {
                    return resolve(token);
                }
                return reject()
            });
        },
        logout: function () {
            return self.getToken().then(function (token) {
                return Vue.http.post(prefix_path + "/user/logout", {
                    "token": token
                }, {
                    emulateJSON: true
                }).then(function (response) {
                    return new Promise(function (resolve, reject) {
                        var result = response.json();
                        /**
                         * 无论接口调用是否正确，都清空本地的token
                         */
                        localStorage.removeItem("token");
                        EventBus.$emit("user.logout");
                        return resolve(result.data);
                    });
                });
            })
        },
        login: function (login_id, pwd) {
            return Vue.http.post(prefix_path + "/user/login", {
                "login_id": login_id,
                password: pwd
            }, {
                emulateJSON: true
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status == 1) {
                        localStorage.setItem('token', result.data);
                        EventBus.$emit("user.login");

                        resolve(result.data);
                    } else {
                        reject(result.error);
                    }
                })
            });
        }
    }

    return self;
});
