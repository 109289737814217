define("service/product", [
    "vue",
    "vue-resource",
    "promise",
    "lodash",
    "service/user"
], function (Vue, http, _promise, _, UserService) {
    'use strict';

    Vue.use(http);

    var prefix_path = MAIN_CONFIH.API_ROOT;

    Vue.http.options.timeout = 0;


    var INSTANCE = {
        list: function (options) {

            var opt = _.assign({
                page: 1,
                s: ""
            }, options)

            return Vue.http.jsonp(prefix_path + "/product/list", {
                "params": opt
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status) {
                        resolve(result.data);
                    } else {
                        reject("加载失败");
                    }
                })
            });
        },

        get: function (product_id) {
            return Vue.http.jsonp(prefix_path + "/product/detail/" + product_id, {}).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status) {
                        resolve(result.data);
                    } else {
                        reject("加载失败");
                    }
                })
            });
        },

        adminGet: function (pid, token) {
            return Vue.http.jsonp(prefix_path + "/product", {
                params: {
                    product_id: pid,
                    api_token: token
                }
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status == 1) {
                        resolve(result.data);
                    }
                    /**
                     * 发现是验证失败的，退出登录并显示重新登入信息
                     */
                    if (result && result.error && result.error.code == 401) {
                        UserService.logout();
                        return reject(result.error);
                    }

                    reject("加载失败");
                });
            });
        },

        adminSave: function (args) {
            return Vue.http.post(prefix_path + "/product/save", {
                api_token: args.token,
                product_id: args.product_id,
                content: args.content,
                price: args.price,
                images: args.images
            }, {
                emulateJSON: true
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status == 1) {
                        return resolve(result.data);
                    }
                    if (result && result.error) {
                        return reject(result.error);
                    }

                    /**
                     * 发现是验证失败的，退出登录并显示重新登入信息
                     */
                    if (result && result.error && result.error.code == 401) {
                        UserService.logout();
                        EventBus.$emit("message", "请重新登录", 2000);
                    }
                });
            });
        },

        adminRemove: function (product_id, token) {
            return Vue.http.post(prefix_path + "/product/remove", {
                "product_id": product_id,
                api_token: token
            }, {
                emulateJSON: true
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status == 1) {
                        return resolve(result.data);
                    }
                    if (result && result.error) {
                        return reject(result.error);
                    }

                    /**
                     * 发现是验证失败的，退出登录并显示重新登入信息
                     */
                    if (result && result.error && result.error.code == 401) {
                        UserService.logout();
                        EventBus.$emit("message", "请重新登录", 2000);
                    }
                });
            });
        }
    }

    return INSTANCE;
});
