define("component/message", [
    "vue",
    "text!component/message.html",
    "css!component/message.css",
    "service/bus",
    "promise"
], function (Vue, tmpl, css, EventBus, _promise) {
    'use strict';

    var Message = Vue.extend({
        data: function () {
            return {
                timeoutID: 0,
                confirmModel: true,
                message: false
            }
        },
        ready: function () {
            var vm = this;
            EventBus.$on("message", function (msg, timeout) {
                vm.confirmModel = false
                vm.message = msg
                if (vm.timeoutID) {
                    clearTimeout(vm.timeoutID)
                }
                vm.timeoutID = setTimeout(function () {
                    vm.message = false
                }, timeout);
            });

            EventBus.$on("confirm", function (msg) {
                vm.confirmModel = true
                vm.message = msg
                if (vm.timeoutID) {
                    clearTimeout(vm.timeoutID)
                }
            });
        },
        template: tmpl,

        methods: {
            Yes: function () {
                EventBus.$emit("confirm.yes")
                this.message = false
            },
            No: function () {
                EventBus.$emit("confirm.no")
                this.message = false
            }
        }
    });

    return {
        install: function (Vue) {
            Vue.component('message', Message)
            /**
             *  注入 $message 方法
             */
            Vue.prototype.$message = function (msg) {
                var timeout = arguments.length > 1 ? arguments[1] : 2000;
                EventBus.$emit("message", msg, timeout)
            }
            Vue.prototype.$confirm = function (msg) {
                return new Promise(function (resolve, reject) {
                    EventBus.$once("confirm.yes", function () {
                        resolve(true)
                    })
                    EventBus.$once("confirm.no", function () {
                        resolve(false)
                    })

                    EventBus.$emit("confirm", msg)
                });
            }
        }
    }
});
