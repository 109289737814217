define("page/home", [
    "vue",
    "text!page/home.html",
    "css!page/home.css",
    "component/post-list",
    "lodash",
    "service/product",
    "service/tags",
    "vue-viewport",
    "service/bus"
], function (Vue, tmpl, css, PostList, _, ProductService, TagService, VueViewport, EventBus) {
    'use strict';

    Vue.use(VueViewport);

    return Vue.extend({
        data: function () {
            return {
                canloadmore: false,
                search: "",
                page: 1,
                list: [],
                tags: [],
                suggest: [],
                tagGroupClass: ""
            }
        },
        created: function () {
            var vm = this;
            this.$on('viewportleave', function () {
                vm.tagGroupClass = "fixed";
            })

            this.$on('viewportenter', function () {
                vm.tagGroupClass = "";
            })

        },
        ready: function () {
            var vm = this;

            EventBus.$on("load.more", function () {
                vm.page += 1;
                vm.fetchData();
            });

            EventBus.$on("product.edited", function (product_id) {
                // ProductService.get(product_id)
                //     .then(function (data) {
                //         var index = _.findIndex(vm.list, function (o) {
                //             return o.product_id = product_id;
                //         });
                //         if (index > -1) {
                //             vm.list.$set(index, data);
                //         }
                //     })
            });
        },
        route: {
            data: function () {
                var vm = this,
                    tags = vm.$route.params.tags || "";

                vm.search = vm.$route.query.s || "";

                vm.tags = _.filter(tags.split(","), function (item) {
                    return item && item.length > 0;
                });

                vm.page = 1;

                vm.fetchData();

                if (this.tags.length > 0) {
                    TagService.suggest(this.tags.join(",")).then(function (data) {
                        vm.suggest = _.map(data, function (item) {
                            return item;
                        });
                    });
                }
            }
        },
        methods: {
            fetchData: function () {
                var vm = this;

                ProductService.list({
                    tag: vm.tags.join(","),
                    page: vm.page,
                    s: vm.search
                }).then(function (data) {
                    if (vm.page == 1) {
                        vm.list = data;
                    } else {
                        vm.list = vm.list.concat(data);
                    }

                    vm.canloadmore = true;
                    if (data.length <= 0) {
                        vm.canloadmore = false;
                    }
                }).catch(function () {
                    vm.canloadmore = false;
                })
            },
            removeTag: function (item) {
                this.tags.$remove(item);

                if (this.tags.length > 0) {
                    this.$router.go({
                        path: '/tag/' + this.tags.join(",")
                    })
                } else {
                    this.$router.go({
                        path: '/'
                    })
                }

            },
            addTag: function (item) {
                if (_.indexOf(this.tags, item) < 0) {
                    this.tags = _.concat(this.tags, item);
                }
                this.$router.go({
                    path: '/tag/' + this.tags.join(",")
                })
            }
        },
        template: tmpl,
        components: {
            "item-list": PostList
        }
    });
});
