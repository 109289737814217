define("component/user-login", [
    "vue",
    "text!component/user-login.html",
    "css!component/user-login.css",
    "service/user",
    "lodash",
    "service/bus"
], function (Vue, tmpl, css, UserService, _, EventBus) {
    'use strict';

    return Vue.extend({
        template: tmpl,

        data: function () {
            return {
                login_id: "",
                pwd: ""
            }
        },

        ready: function () {
            var vm = this;
            EventBus.$on("user.logout", function () {
                vm.login_id = "";
                vm.pwd = "";
            });
        },

        props: {
            show: {
                type: Boolean,
                required: false,
                twoWay: true,
                default: false
            }
        },

        methods: {
            close: function () {
                this.show = false;
            },
            Login: function () {
                var vm = this;
                UserService.login(this.login_id, this.pwd)
                    .then(function (token) {
                        vm.show = false;
                    }).catch(function (error) {
                        vm.$message(_.reduce(error, function (result, item) {
                            result += ", " + item[0];
                            return result;
                        }));
                    })
            }
        }
    });
});
