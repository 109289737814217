define("page/spp", [
    "vue",
    "text!page/spp.html",
    "css!page/spp.css",
    "service/product",
    "vue-swiper",
], function (Vue, tmpl, css, ProductService, VueSwiper) {
    'use strict';

    Vue.use(VueSwiper);

    return Vue.extend({
        data: function () {
            return {
                post_id: 0,
                content: "",
                cover: "",
                price: 0,
                img: [],
                swiperOption: {
                    autoplay: 3000,
                    pagination: '.swiper-pagination',
                    setWrapperSize: true,
                    paginationClickable: false,
                    // autoHeight: true,
                    loop: true
                }
            }
        },

        computed: {
            images: function () {
                return [].concat([this.cover], this.img);
            }
        },

        route: {
            data: function () {
                this.post_id = this.$route.params.post_id || 0;

                var vm = this;

                ProductService.get(this.post_id).then(function (data) {
                    vm.content = data.content;
                    vm.cover = data.cover;
                    vm.price = data.price;
                    vm.img = data.images;

                    console.log(vm.images);
                });

            }
        },
        template: tmpl
    });
});
