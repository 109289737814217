/**
 * 客户端页面路由
 */
define('config/router', ['vue', 'vue-router', 'service/bus'], function (Vue, VueRouter, Bus) {
  'use strict'

  Vue.use(VueRouter)

  var options = {}
  if (IN_PRO) {
    options['history'] = true;
    options['saveScrollPosition'] = true;
    options['root'] = IN_PRO.root || ''
  }

  var router = new VueRouter(options)

  /**
   * url 路由，使用hash 代替真实的url匹配
   * 由于使用AMD方式，这里使用了一种异步引入VM方式实现。
   */
  router.map({
    /**
     * 详情页
     */
    'post/:post_id': {
      component: function (resolve) {
        require(['page/spp'], resolve)
      }
    },
    /**
     * 首页
     */
    '/': {
      component: function (resolve) {
        require(['page/home'], resolve)
      }
    },
    /**
     * list with tag filter
     */
    '/tag/:tags': {
      component: function (resolve) {
        require(['page/home'], resolve)
      }
    }
  })


  return router
});
