define("component/product-edit", [
    "vue",
    "text!component/product-edit.html",
    "css!component/product-edit.css",
    "service/user",
    "service/product",
    "vue-plupload",
    "lodash",
    "service/bus"
], function (Vue, tmpl, css, UserService, ProductService, VuePlupload, _, EventBus) {
    'use strict';

    Vue.use(VuePlupload);

    return Vue.extend({
        template: tmpl,

        data: function () {
            return {
                product: {
                    content: "",
                    price: 0,
                    cover: null,
                    images: []
                },
                UploadCoverOpt: {
                    url: MAIN_CONFIH.API_ROOT + "/upload/image",
                    multi_selection: false,
                    filters: {
                        max_file_size: '8mb',
                        mime_types: [{
                            title: "Image files",
                            extensions: "jpg,gif,png"
                        }]
                    },
                },
                UploadOpt: {
                    url: MAIN_CONFIH.API_ROOT + "/upload/image",
                    multi_selection: false,
                    filters: {
                        max_file_size: '8mb',
                        mime_types: [{
                            title: "Image files",
                            extensions: "jpg,gif,png"
                        }]
                    },
                }
            }
        },

        props: {
            show: {
                type: Boolean,
                required: false,
                default: false,
                twoWay: true
            },
            pid: {
                type: Number,
                required: true,
                default: 0,
                twoWay: true
            }
        },

        watch: {
            pid: function (val, oldVal) {
                if (!val) {
                    return;
                }
                this.fetchData();
            }
        },

        methods: {
            fetchData: function () {
                var vm = this;

                vm.product = {
                    content: "",
                    price: 0,
                    cover: null,
                    images: []
                };

                UserService.getToken()
                    .then(function (token) {
                        return ProductService.adminGet(vm.pid, token)
                    })
                    .then(function (data) {
                        vm.product = data;
                    }, function (error) {
                        if (error && error.code == 401) {
                            vm.$message("请重新登录");
                            vm.show = false;
                        }
                    });
            },
            Close: function () {
                this.show = false;
            },

            handleError: function (uploader, err) {
                this.$message("请上传符合规格的图片");
            },

            handleAdded: function (uploader, files) {
                var vm = this;
                _.each(files, function (file) {
                    vm.product.images.push(file);
                });
                UserService.getToken()
                    .then(function (token) {
                        uploader.setOption({
                            multipart_params: {
                                api_token: token
                            },
                            file_data_name: "upload"
                        });
                        uploader.start()
                    })
            },

            handleUploaded: function (uploader, file, result) {
                var vm = this;
                var result = JSON.parse(result.response);
                if (result && result.status == 1) {
                    var index = _.findIndex(vm.product.images, function (o) {
                        return o.id == file.id;
                    });
                    console.log(index);
                    if (index > -1) {
                        vm.product.images.$set(index, result.data);
                    }
                    console.log(vm.product.images);
                    return;
                }
                if (result && result.error) {
                    vm.$message(_.reduce(result.error, function (result, item) {
                        result += ", " + item[0];
                        return result;
                    }));
                }
            },

            removeImg: function (item) {
                console.log(item);
                this.product.images.$remove(item);
            },

            handleCoverUploaded: function (uploader, file, result) {
                var vm = this;
                var result = JSON.parse(result.response);
                if (result && result.status == 1) {
                    vm.product.cover = result.data;
                    return;
                }
                if (result && result.error) {
                    vm.$message(_.reduce(result.error, function (result, item) {
                        result += ", " + item[0];
                        return result;
                    }));
                }
            },
            handleCoverAdded: function (uploader, files) {
                UserService.getToken()
                    .then(function (token) {
                        uploader.setOption({
                            multipart_params: {
                                api_token: token
                            },
                            file_data_name: "upload"
                        });
                        uploader.start()
                    })
            },

            SaveProduct: function () {
                var vm = this;

                UserService.getToken()
                    .then(function (token) {
                        var images = _.filter(vm.product.images, function (o) {
                            return o.url && o.id;
                        });
                        images = _.map(images, function (item) {
                            return item.id;
                        });

                        images.unshift(vm.product.cover.id);

                        return ProductService.adminSave({
                            "token": token,
                            product_id: vm.pid,
                            content: vm.product.content,
                            price: vm.product.price,
                            images: images
                        });
                    })
                    .then(function () {
                        vm.$message("保存成功");
                        vm.show = false;
                        EventBus.$emit("product.edited", vm.pid);
                    }, function (error) {
                        console.log(error);

                        vm.$message(_.reduce(error, function (result, item) {
                            result += ", " + item[0];
                            return result;
                        }));
                    })
            },

            addProduct: function () {
                var vm = this;

                vm.pid = 0;
                vm.product = {
                    content: "",
                    price: 0,
                    cover: null,
                    images: []
                };
            },

            removeProduct: function () {
                var vm = this;

                vm.$confirm("确认删除该产品？")
                    .then(function (yes) {
                        if (yes) {
                            UserService.getToken()
                                .then(function (token) {
                                    return ProductService.adminRemove(vm.pid, token)
                                })
                                .then(function (data) {
                                    vm.$message("删除成功");
                                    vm.show = false;
                                })
                        }
                    })
            }
        }
    });
});
