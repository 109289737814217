define("service/tags", [
    "vue",
    "vue-resource",
    "promise",
    "lodash"
], function (Vue, http, _promise, _) {
    'use strict';

    Vue.use(http);

    var prefix_path = MAIN_CONFIH.API_ROOT;

    Vue.http.options.timeout = 0;

    return {
        suggest: function (opt) {

            return Vue.http.jsonp(prefix_path + "/tag/suggest", {
                params: {
                    "tags": opt
                }
            }).then(function (response) {
                return new Promise(function (resolve, reject) {
                    var result = response.json();

                    if (result && result.status) {
                        resolve(result.data);
                    } else {
                        reject("加载失败");
                    }
                })
            });
        }
    }
});
