define("component/backToTop", [
    "vue",
    "text!component/backToTop.html",
    "css!component/backToTop.css",
    "scroll-to"
], function(Vue, tmpl, css, ScrollTo) {
    'use strict';

    return Vue.extend({
        template: tmpl,

        data: function() {
            return {
                show: false
            }
        },

        ready: function() {
            var vm = this;
            document.addEventListener("scroll", function() {
                var offsetX = document.documentElement.scrollTop || window.pageYOffset;
                vm.show = (offsetX > window.innerHeight / 2);
            });
        },

        methods: {
            Top: function() {
                ScrollTo(0, 0);
            }
        }
    });
});
